<template>
	<div class="container">
		<div class="left">
			<ResumeForm />
		</div>
		<div class="right">
			<ResumePreview />
		</div>
	</div>
</template>

<script>
// @ est un alias vers /src
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import ResumeForm from '@/components/Generator/ResumeForm.vue'
import ResumePreview from '@/components/Generator/ResumePreview.vue';
import { user_exists } from '@/utils/user.js';
import {BACK_HOST, BACK_PORT} from '@/utils/ip'

export default {
	name: 'HomeView',
	components: {
		ResumeForm,
		ResumePreview
	},
	computed: {
		...mapGetters({
			resume_data: 'getResumeData',
			user: 'getUserData',
			empty_resume: 'getEmptyResume'
		}),
	},
	mounted() {
		localStorage.goToLinkNext = this.$route.query.as
		if (user_exists(this.user.id) == false) {
			this.$router.push({ name: 'login' });
		} else {
			this.editUserAsAdmin()
		}
	},
	methods: {
		...mapActions(['updateResumeFormData']),
		editUserAsAdmin() {
			if (this.$route.query.as) {
				localStorage.goToLinkNext = this.$route.query.as
				axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/is_admin`, { userId: this.user.id })
				.then((response) => {
					if (response.data.is_admin == false)
						this.$router.push({ name: 'login' });
					else
						localStorage.goToLinkNext = ""
				})
				.catch(error => {
					console.error('Error checking admin status:', error);
					this.$router.push({ name: 'login' });
				});
			} else {
				this.fetchData();
			}
		},
		fetchData() {
			axios.get(`https://${BACK_HOST}:${BACK_PORT}/api/data`, { params: { userId: this.user.id }}).then((response) => {
				if (response.data.data === null || response.data.data === undefined) {
					console.error("resume_data is null");
				}
				else {
					//this.updateResumeFormData(response.data.data);
					//this.updateResumeFormData(response.data.data);
				}
			}).catch(error => console.error('Error:', error));
		}
	},
}
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 3rem - 10px); /* hauteur total de la fenetre moins la hauteur du header  */
  position: relative;
  z-index: 1;
  overflow: hidden;
  top: 10px;
}

.left {
  background-color: #fff;
  flex: 1;
  padding: 1.25rem;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease-in-out;
}

::-webkit-scrollbar-corner {
  background: #f1f1f1;
}

.right {
  position: sticky;
  top: 0;
  flex: 1;
  padding: 1.25rem;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
}
</style>
