<template>
	<PopupNotification ref="notification" />
	<div class="resume-header">
		<div class="name title text" >{{ this.user.firstName }}</div>
		<input type="submit" class="orange_button" style="margin: 5px;height: fit-content;" value="Annuler" @click="cancelChanges" :disabled="this.isReadOnly || !this.isEdited"/>
		<input type="submit" class="orange_button" style="margin: 5px;height: fit-content;" value="Sauvegarder" @click="submitForm" :disabled="this.isReadOnly || !this.isEdited"/>
		<input v-show="!this.isReadOnly" type="submit" class="orange_button" style="margin: 5px;height: fit-content;" :value="this.isEditingElse ? 'Approuver' : 'Valider et envoyer'" @click="isValidateModalVisible = true" :disabled="this.isEdited" />
	</div>
	<form @submit.prevent="">
		<ProfileForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<EducationForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<LanguagesForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<SkillForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>
		<ExperiencesForm :key="this.forceRefresh" :isReadOnly="this.isReadOnly" @edited="this.sectionHasBeenEditedHandler()"/>

		<section title="Slide 5" class="section_checkbox text" v-if="this.isEditingElse">
			<div class="slider-checkbox text">
				<input id="remove_page_checkbox" type="checkbox" v-on:input="this.isEdited = true" :checked="this.removePageState" />
				<label for="remove_page_checkbox">
					<span id="ball"></span>
				</label>
			</div>
			Page en trop ?
		</section>
	</form>
	

	<div v-if="isValidateModalVisible" class="modal">
		<div v-if="!this.isEditingElse" class="modal-content">
			<h2 class="text">Confirmer la validation</h2>
			<p class="text">Vous êtes sur le point de valider votre CV ! Si vous pensez que votre CV est complet vous pouvez alors confirmer la validation.</p>
			<p class="text">Après validation, vous pourrez toujours le consulter en lecture seule</p>
			<div>
				<button @click="validateResume" class="orange_button"  style="background-color: green; color: white; margin-right: 1rem;">VALIDER et ENVOYER</button>
				<button @click="isValidateModalVisible = false" class="cancel-button">Annuler</button>
			</div>
		</div>
		<div v-else class="modal-content">
			<h2 class="text">Confirmer l'approbation</h2>
			<h3 v-if="resumeStatus == 0" class="text" style="color: red; font-size: larger;">Attention: ce CV n'a pas encore été validé par {{ this.user.firstName }} ! Vous pouvez tout de meme l'approuver si vous le souhaitez.</h3>
			<p class="text">Vous êtes sur le point d'approuver le CV de {{ this.user.firstName }} ! Approuver le CV signifie qu'il est prêt à être utilisé et n'a plus pour vocation de changer</p>
			<p class="text">Après approbation, vous pourrez toujours le consulter en lecture seule</p>
			<p class="text">Il sera toujours possible de revenir en arrière</p>
			<div>
				<button @click="validateResume" class="orange_button"  style="background-color: green; color: white; margin-right: 1rem;">APPROUVER</button>
				<button @click="isValidateModalVisible = false" class="cancel-button">Annuler</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue';
import ProfileForm from '@/components/Generator/ProfileForm.vue';
import EducationForm from '@/components/Generator/EducationForm.vue';
import LanguagesForm from '@/components/Generator/LanguagesForm.vue';
import SkillForm from '@/components/Generator/SkillsForm.vue';
import ExperiencesForm from '@/components/Generator/ExperiencesForm.vue';
import PopupNotification from '@/components/Utils/PopupNotification.vue';
import axios from 'axios';
import {BACK_HOST, BACK_PORT} from '@/utils/ip'
import ResumeStatus from '@/utils/enums/resumeStatus';



export default {
	name: 'ResumeForm',
	components: {
		ProfileForm,
		EducationForm,
		LanguagesForm,
		SkillForm,
		ExperiencesForm,
		PopupNotification,
	},
	data() {
		
		return {
			isValidateModalVisible: false,
			resumeStatus: ref(ResumeStatus.ON_GOING),
			isReadOnly: ref(false),
			isEditingElse: ref(false),
			removePageState: ref(false),

			isEdited: ref(false),
			forceRefresh: ref(0),
			
		};
		
	},
	computed: {
		...mapGetters({
			resume_data: 'getResumeData',
			user: 'getUserData',
		}),
		
	},
	watch: {
		resumeStatus: {
			handler() {
				console.log("resumeStat : " + this.resumeStatus)
				if ((this.resumeStatus == ResumeStatus.VALIDATE && this.isEditingElse == false) || this.resumeStatus == ResumeStatus.APPROVED) {
					this.isReadOnly = true
				}
				console.log(this.isReadOnly)
			}
		},
		isEditingElse: {
			handler() {
				console.log("Editing else : " + this.resumeStatus)
				if ((this.resumeStatus == ResumeStatus.ON_GOING && this.isEditingElse == false) || this.resumeStatus == ResumeStatus.APPROVED)
					this.isReadOnly = true
				console.log(this.isReadOnly)
			}
		},
	},
	methods: {
		...mapActions(['updateResumeFormData']),
		forceRerender() {
			this.forceRefresh += 1;
		},
		async fetchDatas() {
			/*console.log(this.user.id)
			await this.updateResumeFormData(this.empty_resume)
			console.log(this.user.id)*/
			await axios.get(`https://${BACK_HOST}:${BACK_PORT}/api/data`, { params: { userId: this.isEditingElse != "" ? this.$route.query.as : this.user.id }})
			.then(async (response) => {
				if (response.status != 204) {
					try {
						await this.updateResumeFormData(response.data.data)
					} catch (error) {
						console.error(error)
					}
				}
				else
					await this.updateResumeFormData(this.empty_resume)
				this.forceRerender()
			})
			.catch((error) => {
				try {
					console.error('Error fetching data:', error)
					this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
				} catch {
					console.error("NotificationError")
				}
				
			});

			
		},
		async cancelChanges() {
			await this.fetchDatas();
			this.isEdited = false
		},

		async submitForm() {
			let form_data = {
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
				role: this.user.role,
				shouldRemovePage: document.getElementById("remove_page_checkbox") != null ? document.getElementById("remove_page_checkbox").checked : false,
				data: {
					profile: this.resume_data.profile,
					education: this.resume_data.education,
					languages: this.resume_data.languages,
					skills: this.resume_data.skills,
					experience: this.resume_data.experience
				}
			};
			axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/data`, form_data).then(() => {
				this.$refs.notification.addNotification("Sauvegarde réussie.", "success", 3000);
				this.isEdited = false
			})
			.catch((error) => {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification("Une erreur est survenue lors de la sauvegarde.", "error", 3000);
			})
			
		},
		async validateResume() {
			let payload = {
				isEditingElse: this.isEditingElse,
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
			}
			
			// D'abbord on save le CV
			let form_data = {
				userId: this.isEditingElse == true ? this.$route.query.as : this.user.id,
				role: this.user.role,
				data: {
					profile: this.resume_data.profile,
					education: this.resume_data.education,
					languages: this.resume_data.languages,
					skills: this.resume_data.skills,
					experience: this.resume_data.experience
				}
			};
			axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/data`, form_data).then(() => {

				this.isEdited = false
				axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/validate_resume`, payload)
				.then((response) => {
					if (response.status == 200) {
						this.resumeStatus = response.data
						this.isValidateModalVisible = false
						this.$refs.notification.addNotification("Le CV à bien été validé !", "success", 3000);
					}
				})
				.catch((error) => {
					this.$refs.notification.addNotification(error.response.data.message, "error", 3000);
				})
			})
			.catch((error) => {
				console.error('Error submitting form:', error);
				this.$refs.notification.addNotification("Une erreur est survenue lors de la sauvegarde.", "error", 3000);
			})
		},
		async getResumeStatus() {
			axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/resume_status`, {"userId": this.isEditingElse ? this.$route.query.as : this.user.id}, {headers: {'Content-Type': 'application/json'}})
			.then((response) => {
				this.resumeStatus = response.data.resumeStatus
				this.isDatasLoaded = true
			})
			.catch(() => {
				console.log("Can't get resume status")
			})
		},
		async getRemovePageStatus() {
			await axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/rmv_pg_status`, {"userId": this.isEditingElse ? this.$route.query.as : this.user.id}, {headers: {'Content-Type': 'application/json'}})
			.then((response) => {
				this.removePageState = response.data.status
			})
			.catch(() => {
				console.log("Can't get remove page status")
			})
		},
		sectionHasBeenEditedHandler() {
			this.isEdited = true
		}
	},
	async beforeMount() {
		if (this.user.id == "")
			this.$router.push({ name: 'login' });
		return
	},
	/* fonction apellée au moment de l'affichage de la page, ajoute un évènement "click" sur les collpasibles qui permet de déployer la section en dessous	*/
	async mounted() {
		this.isEditingElse = this.$route.query.as != undefined
		this.getResumeStatus();
		this.getRemovePageStatus();
		await this.fetchDatas()

		await axios.get(`https://${BACK_HOST}:${BACK_PORT}/api/user/${this.isEditingElse != "" ? this.$route.query.as : this.user.id}`)
		.then(response => {
			this.user.firstName = response.data.firstName
			this.user.role = response.data.role
			this.user.isEditingElse = this.isEditingElse
		})
		.catch((error) => {
			console.error('Error fetching data:', error)
			try {
				this.$refs.notification.addNotification("Erreur lors de la récupération des données.", "error", 3000);
			} catch {
				console.log()
			}
		});
	}
};
</script>

<style>
/* section déroulante */
.collapsible {
	background-color: #fcfcfc;
	color: #002339;
	cursor: pointer;
	padding: 1.125rem;
	width: 100%;
	border: #002339 0px;
	text-align: left;
	outline: none;
	font-size: 0.75rem;
	text-transform: uppercase;
	border-bottom: 0.125rem solid #f9a825;
	margin-top: 0.3125rem;
}

/* ajoute un + sur la droite du collapsible*/
.collapsible:after {
	content: '\002B';
	color: #002339;
	float: right;
	margin-left: 0.3125rem;
}

.content {
	animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) alternate both;
}

@keyframes swing-in-top-fwd {
  0% {
	-webkit-transform: rotateX(-100deg);
			transform: rotateX(-100deg);
	-webkit-transform-origin: top;
			transform-origin: top;
	opacity: 0;
  }
  100% {
	-webkit-transform: rotateX(0deg);
			transform: rotateX(0deg);
	-webkit-transform-origin: top;
			transform-origin: top;
	opacity: 1;
  }
}

/* ajoute un - sur la droite du collapsible activé */
.active:after {
	content: "\2212";
}

/* change la couleur du collapsible activé */
.active,
.collapsible:hover {
	background-color: #f5f5f5;
}

/* style du contenu du collapsible */
.content {
	padding: 1.125rem;
	display: none;
	overflow: hidden;
	background-color: #f5f5f5;
}

.form-group {
	margin-bottom: 2.1875rem;
	animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@keyframes tracking-in-expand {
  0% {
	letter-spacing: -0.5em;
	opacity: 0;
  }
  40% {
	opacity: 0.6;
  }
  100% {
	opacity: 1;
  }
}

.delete_button,
.plus_button {
	border-radius: 50%;
	border-width: 0px;
	color: white;
	width: 0.93rem;
	height: 0.93rem;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.plus_button {
	background-color: #f9a825;
	background-image: url('@/assets/images/add.svg');
	background-repeat: no-repeat;
	background-size: contain;
}

.delete_button {
	background-color: #002339;
	background-image: url('@/assets/images/close.svg');
	background-repeat: no-repeat;
	background-size: contain;
	float: right;
	cursor: pointer;
}

.delete_button:disabled,
.plus_button:disabled {
	visibility: hidden;
	cursor: default;
}

/* affiche les éléments cote à cote */
.side_by_side_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.name {
	text-align: center !important;
	font-size: 25px !important;
	position: sticky;
	top: -50px;
	background: white;
	z-index: 2;
	padding: 25px !important;
	width: 93% !important;
	text-decoration-line: underline;
	text-decoration-color: #f9a825;
}

.resume-header {
	display: flex;
	position: sticky;
	top: -40px;
	background: white;
	z-index: 2;
	align-items: center;
}

.orange_button:disabled {
	background-color: grey;
	animation: none;
	cursor: default;
}

.orange_button:disabled:hover {
	color: white;
	animation: none;
}

/* Slide 5 */
.slider-checkbox {
	width: 3em;
	height: 1.5em;
	margin: 20px 5px;
}

.slider-checkbox * {
	transition: 250ms;
	box-sizing: border-box;
}

.slider-checkbox input[type="checkbox"] {
	display: none;
}

.slider-checkbox label {
	display: inline-block;
	width: 100%;
	height: 95%;
	background: grey;
	border-radius: 0.7em;
	padding-top: 0.2em;
	padding-left: 0.2em;
}

.slider-checkbox span {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: #FFF;
    border: none;
    border-radius: 2em;
    position: relative;
    left: -0.8em;
}

.slider-checkbox input[type="checkbox"]:checked ~ label {
	background: #f9a927;
	padding-left: 2.5em;
}

.section_checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin: 20px auto;
    justify-content: center;
}

</style>
