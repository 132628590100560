<template>
	<AdminForm />
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";
import AdminForm from "@/components/Admin/AdminForm.vue"
import { user_exists } from "@/utils/user.js";
import {BACK_HOST, BACK_PORT} from '@/utils/ip'

export default {
	name: "AdminView",
	components: {
		AdminForm
	},
	computed: {
		...mapGetters({
			user: 'getUserData',
		}),
	},
	created() {
		if (user_exists(this.user.id) == false) {
			this.$router.push({ name: 'login' });
		}
		axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/is_admin`,
			{ userId: this.user.id },
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		).then(response => {
			if (!response.data.is_admin) {
				this.$router.push({ path: '/' });
			}
		}).catch(error => {
			console.error('Error checking admin status:', error);
			this.$router.push({ path: '/' });
		});
	},
	methods: {
		
	}
}
</script>

<style>

</style>
