<template>
	<button type="button" class="collapsible" id="exp_collaps">Expériences professionnelles</button>
	<div class="content">
		<div class="form-group" v-for="(experience, experience_index) in experienceList" :key="experience_index">
			<button class="delete_button" type="button" style="margin-right: 0.8rem" v-on:click="removeExperience(experience_index)" :disabled="this.isReadOnly"></button>
			<button type="button" class="exp_collapsible">
				<div class="side_by_side_container exp" style="margin-bottom: -0.75rem;">
					<div v-if="experience[1].value == ''">Poste</div>
					<div v-else>{{ experience[1].value }}</div>
					&nbsp;-&nbsp;
					<div v-if="experience[0].value == ''">Nom de l'entreprise</div>
					<div v-else>{{ experience[0].value }}</div>
				</div>
			</button>
			<div class="content exp_collapsible_content">
				<!-- affiche le nom de l'entreprise, le poste et le lieu sur la première ligne -->
				<div class="side_by_side_container" style="margin-bottom: 30px; flex-wrap: nowrap">

					<div class="input-wrapper exp" v-for="i in range(3)" :key="i">
						<span class="input_circle"></span>
						<span class="label text required" :class="{ 'hidden': experience[i].isLabelHidden }">{{ experience[i].label }}</span>
						<input type="text" :name="experience[i].name + experience_index" v-model="experience[i].value" @focus="toggleLabel(experience_index, i)" @blur="toggleLabel(experience_index, i)"  :readonly="this.isReadOnly" v-on:input="this.$emit('edited')">
					</div>
				</div>
				<!-- affiche les dates et si le poste est actuellement occupé -->
				<!--<div class="space side_by_side_container exp">-->
					<div class="input-wrapper exp">
						<span class="input_circle"></span>
						<span class="side_label text required">{{ experience[3].label }} :</span>
						<input type="date" :name="experience[3].name + experience_index" v-model="experience[3].value" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')">
					</div>
					<div v-if="!experience[5].value" class="input-wrapper exp">
						<span class="input_circle"></span>
						<span class="side_label text required">{{ experience[4].label }} :</span>
						<input type="date" :name="experience[4].name + experience_index" v-model="experience[4].value" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')">
					</div>
					<div class="input-wrapper exp">
						<input type="checkbox" :name="experience[5].name + experience_index" v-model="experience[5].value" :disabled="this.isReadOnly" v-on:input="this.$emit('edited')">
						<span class="side_label text">{{ experience[5].label }}</span>
					</div>
				<!--</div>-->
				<!-- affiche le contexte -->
				<div class="space">
					<div class="side_by_side_container exp text" style="margin-bottom: 0.5rem;">
						Déscription de l'entreprise
						<div class="tooltip" >
							<img src="@/assets/images/interrogation.svg" style="width: 15px;" />
							<span class="tooltiptext">Décrivez l'entreprise en général (pas que de votre poste) en quelques mots. Vous pouvez parler de quelques objectifs que vous avez eu ou des technologies que vous avez utilisé</span>
						</div>
						<!--<button class="generate_button text" type="button" style="margin-right: 0.5rem;" :disabled="generate" @click="generate_context(experience_index)">générer</button>-->
						<RingLoader :loading="generate" :color="color" :size="24" />
						<span class="text" style="color: red; font-size: small;" v-if="experience[6].error != ''">{{ experience[6].error }}</span>
					</div>
					<textarea v-model="experience[6].value" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')"></textarea>
				</div>
				<!-- affiche les objectifs -->
				<div class="space">
					<div class="side_by_side_container exp">
						<span class="input_circle"></span>
						<span class="side_label text">Objectifs:</span>
						<button class="plus_button" type="button" @click="addObjective(experience_index)" :disabled="this.isReadOnly"></button>
					</div>
					<div class="side_by_side_container exp" v-for="(obj, obj_index) in experience[7].Objectives" :key="obj_index">
						<!--<span class="input_circle"></span>-->
						<input class="large_input text" type="text" v-model="experience[7].Objectives[obj_index]" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')" v-on:focusout="addObjective(experience_index)"/>
						<button class="delete_button" type="button" @click="removeObjective(obj_index, experience_index)" :disabled="this.isReadOnly"></button>
					</div>
				</div>
				<!-- affiche les tâches réalisées -->
				<div class="space">
					<div class="side_by_side_container exp">
						<span class="input_circle"></span>
						<span class="side_label text">Tâches réalisées:</span>
						<div class="tooltip" >
							<img src="@/assets/images/interrogation.svg" style="width: 15px;" />
							<span class="tooltiptext text">Les tâches correspondent aux différents travaux que vous avez fait. Sélectionnez parmi la liste déroulante plusieurs tâches que vous pourrez ensuite modifier et détailler. Ajoutez "Autre" pour ajouter une tâche spécifique qui ne serait pas dans la liste.</span>
						</div>
						<div class="select-dropdown">
							<select :name="'task_list' + experience_index" style="margin: 5px;width: 200px;">
								<option v-for="(task) in this.tasks_options" :value="task" :key="task">{{ task }}</option>
							</select>
						</div>
						<button class="plus_button" type="button" @click="addTask(experience_index)" :disabled="this.isReadOnly"></button>
					</div>
					<div class="side_by_side_container exp" v-for="(task, task_index) in experience[8].Tasks" :key="task_index">
						<!--<span class="input_circle"></span>-->
						<input class="large_input text" type="text" v-model="experience[8].Tasks[task_index]" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')"/>
						<button class="delete_button" type="button" @click="removeTask(task_index, experience_index)" :disabled="this.isReadOnly"></button>
					</div>
				</div>
				<!-- affiche les environnement -->
				<div class="space">
					<div class="side_by_side_container exp">
						<span class="input_circle"></span>
						<span class="side_label text">Environnement:</span>
						<div class="tooltip" >
							<img src="@/assets/images/interrogation.svg" style="width: 15px;" />
							<span class="tooltiptext text">L'environnement correspond aux technologies et aux différents systemes utilisées</span>
						</div>
					</div>
					<div class="side_by_side_container exp">
						<div class="side_by_side_container exp">
							<div v-for="(env, env_index) in experience[9].Environments" :key="env_index" style="margin: 2px;">
								<div class="side_by_side_container exp tag_container">
									<span class="tag text">{{ env }}</span>
									<button class="delete_button" @click="removeEnvironment(env_index, experience_index)" type="button" :disabled="this.isReadOnly"></button>
								</div>
							</div>
						</div>
						<div class="side_by_side_container exp">
							<input v-if="isNewEnvVisible === experience_index" ref="newEnvInput" type="text" v-model="newEnv" placeholder="Environnement" @keyup.enter="addEnvironment(experience_index)" :readonly="this.isReadOnly" v-on:input="this.$emit('edited')" v-on:focusout="addEnvironment(experience_index)">
							<button v-if="isNewEnvVisible === experience_index" class="delete_button" @click="toggleSearch(experience_index)" type="button" :disabled="this.isReadOnly"></button>
							<button v-else class="plus_button" @click="toggleSearch(experience_index)" type="button" :disabled="this.isReadOnly"></button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<button class="orange_button" type="button" @click="addExperience" :disabled="this.isReadOnly">ajouter une experience</button>
	</div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import RingLoader from '../Utils/RingLoader.vue';
import {BACK_HOST, BACK_PORT} from '@/utils/ip'
import { TasksOptions } from '@/utils/enums/dropdownOptions';

export default {
	name: "ExperiencesForm",
	props: ['isReadOnly'],
	emits: ['edited', 'input', 'click'],
	// eslint-disable-next-line no-unused-vars
	setup(props) {
	},
	data() {
		return {
			isNewEnvVisible: null,
			newEnv: null,
			experienceList: [[
				{ name: 'exp_company', label: "Nom de l'entreprise", value: "", type: String, isLabelHidden: false },
				{ name: 'exp_position', label: "Poste", value: "", type: String, isLabelHidden: false },
				{ name: 'exp_location', label: 'Lieu', value: '', type: String, isLabelHidden: false },
				{ name: 'exp_startDate', label: "Date d'arrivée", value: "", type: Date },
				{ name: 'exp_endDate', label: "Date de départ", value: "", type: Date },
				{ name: 'exp_currentPost', label: "Poste actuel", value: false, type: Boolean },
				{ name: 'exp_desciption', label: "Contexte général", error: "", value: "", type: String },
				{ name: 'exp_objectives', label: "Objectifs", Objectives: [""] },
				{ name: 'exp_tasks', label: "Taches", Tasks: [] },
				{ name: 'exp_environments', label: "Environnements", Environments: [] },
			]],
			tasks_options: TasksOptions,
			generate: false,
			color: '#002239',
		}
	},
	components: {
      RingLoader,
    },
	computed: {
		...mapGetters({
			experience: 'getExperienceData',
			user: 'getUserData',
			empty_resume: 'getEmptyResume'
		})
	},
	created() {
		if (this.experience) {
			this.experienceList = []
			this.experience.forEach(element => {
				this.experienceList.push([
					{ name: 'exp_company', label: "Nom de l'entreprise", value: element["company"], type: String, isLabelHidden: element["company"] != "" },
					{ name: 'exp_position', label: "Poste", value: element["position"], type: String, isLabelHidden: element["position"] != "" },
					{ name: 'exp_location', label: 'Lieu', value: element["location"], type: String, isLabelHidden: element["location"] != "" },
					{ name: 'exp_startDate', label: "Date d'arrivée", value: element["start_date"], type: Date },
					{ name: 'exp_endDate', label: "Date de depart", value: element["end_date"], type: Date },
					{ name: 'exp_currentPost', label: "Poste actuel", value: element["current_job"], type: Boolean },
					{ name: 'exp_desciption', label: "Contexte …", value: element["description"], type: String },
					{ name: 'exp_objectives', label: "Objectifs", Objectives: element["objectives"] },
					{ name: 'exp_tasks', label: "Taches", Tasks: element["tasks"] },
					{ name: 'exp_environments', label: "Environnements", Environments: element["environments"] },
				])
			});
		}
	},
	methods: {
		...mapActions(['updateExperienceFormData']),
		/* fonctions pour ajouter ou retirer des champs de données dans le formulaire */
		addExperience() {
			this.experienceList.push([
				{ name: 'exp_company', label: "Nom de l'entreprise", value: "", type: String, isLabelHidden: false },
				{ name: 'exp_position', label: "Poste", value: "", type: String, isLabelHidden: false },
				{ name: 'exp_location', label: 'Lieu', value: '', type: String, isLabelHidden: false },
				{ name: 'exp_startDate', label: "Date d'arrivée", value: "", type: Date },
				{ name: 'exp_endDate', label: "Date de départ", value: "", type: Date },
				{ name: 'exp_currentPost', label: "Poste actuel", value: false, type: Boolean },
				{ name: 'exp_desciption', label: "Contexte général", value: "", type: String },
				{ name: 'expobjectives', label: "Objectifs", Objectives: [""] },
				{ name: 'exp_tasks', label: "Taches", Tasks: [] },
				{ name: 'exp_environments', label: "Environnements", Environments: [] },
			])
		},
		removeExperience: function (index) {
			this.experienceList.splice(index, 1);
			this.$emit('edited')
		},
		addObjective(experience_index) {
			this.experienceList[experience_index][7].Objectives.push("");
			this.$emit('edited')
		},
		removeObjective(obj_index, experience_index) {
			this.experienceList[experience_index][7].Objectives.splice(obj_index, 1);
			this.$emit('edited')
		},
		addTask(experience_index) {
			let task = document.getElementsByName("task_list" + experience_index)[0].value
			this.experienceList[experience_index][8].Tasks.push(task);
			if (task != "Autre")
				this.tasks_options.splice(this.tasks_options.indexOf(task), 1)
			this.$emit('edited')
		},
		removeTask(task_index, experience_index) {
			var task = this.experienceList[experience_index][8].Tasks[task_index];
			if (task != "Autre")
				this.tasks_options.push(task)
			this.experienceList[experience_index][8].Tasks.splice(task_index, 1);
			this.$emit('edited')
		},
		addEnvironment(experience_index) {
			if (this.newEnv != null && this.newEnv.trim() !== '') {
				this.experienceList[experience_index][9].Environments.push(this.newEnv);
				this.newEnv = '';
			}
			this.toggleSearch(experience_index);
			this.$emit('edited')
		},
		removeEnvironment(env_index, experience_index) {
			this.experienceList[experience_index][9].Environments.splice(env_index, 1);
			this.$emit('edited')
		},
		generate_context(index) {
			this.generate = true;
			this.experienceList[index][6].error = "";
			axios.post(`http://${BACK_HOST}:${BACK_PORT}/api/generate_context`, { 
				userId: this.user.id,
				data: {
					company: this.experienceList[index][0].value,
					position: this.experienceList[index][1].value,
					location: this.experienceList[index][2].value,
					start_date: this.experienceList[index][3].value,
					end_date: this.experienceList[index][4].value,
					current_job: this.experienceList[index][5].value,
					description: this.experienceList[index][6].value,
					objectives: this.experienceList[index][7].Objectives,
					tasks: this.experienceList[index][8].Tasks,
					environments: this.experienceList[index][9].Environments
				}
			}).then((response) => {
				this.experienceList[index][6].value = response.data.message;
			}).catch((error) => {
				console.error('Error:', error);
				this.experienceList[index][6].error = error.response.data.message;
			} 
			).finally(() => {
				this.generate = false;
			});
		},

		/* 
			Ajoute un évènement click sur le collapsible qui contient les infos de l'expérience pro.
			La fonction est appellée à l'affichage et à chaque mise à jour.
			Le flag clickEventListenerAdded sert à savoir si on à déjà ajouté l'event car ajouter un évènement sur
			un élément qui le contient déjà le supprime.
		*/
		create_events_collapsible() {
			var coll = document.getElementsByClassName("exp_collapsible");
			var i;

			for (i = 0; i < coll.length; i++) {
				if (!coll[i].clickEventListenerAdded) {
					coll[i].addEventListener("click", function () {
						this.classList.toggle("exp_active");
						var content = this.nextElementSibling;

						if (content.style.display === "block") {
							content.style.display = "none";
						} else {
							content.style.display = "block";
						}
					});
					coll[i].clickEventListenerAdded = true;
				}
			}
		},
		/* fait bouger le label quand on clique sur le champ input */
		toggleLabel(index, field_index) {
			const inputElement = document.querySelector(`input[name="${this.experienceList[index][field_index].name + index}"]`);
			if (inputElement != null) {
				const hasText = inputElement.value.trim() !== '';
				if (!hasText)
					this.experienceList[index][field_index].isLabelHidden = !this.experienceList[index][field_index].isLabelHidden;
			}
		},
		/* affiche la barre de recherche lors de l'ajout d'environnement */
		toggleSearch(experience_index) {
			this.isNewEnvVisible = this.isNewEnvVisible === experience_index ? null : experience_index;
			if (this.isNewEnvVisible != null) {
				this.$nextTick(() => {
					this.$refs.newEnvInput[0].focus();
				});
			}
		},
		/* créé une liste de n nombres à partir de start */
		range(n, start = 0) {
			let arr = [];

			for (var i = start; i < n + start; i++)
				arr.push(i);
			return (arr);
		},
		updateData() {
			let new_data = [];

			for (let i = 0; i < this.experienceList.length; i++) {
				new_data.push({
					company: this.experienceList[i][0].value,
					position: this.experienceList[i][1].value,
					location: this.experienceList[i][2].value,
					start_date: this.experienceList[i][3].value,
					end_date: this.experienceList[i][4].value,
					current_job: this.experienceList[i][5].value,
					description: this.experienceList[i][6].value,
					objectives: this.experienceList[i][7].Objectives,
					tasks: this.experienceList[i][8].Tasks,
					environments: this.experienceList[i][9].Environments
				})
			}
			if (this.experienceList.length == 0) {
				new_data.push(this.empty_resume.experience[0])
			}
			this.updateExperienceFormData(new_data);
		}
	},
	/* mounted est appelée au moment du premier affichage de la page */
	mounted() {
		this.create_events_collapsible();
		var profileContent = document.getElementById("exp_collaps");
		profileContent.addEventListener("click", function () {
			this.classList.toggle("active");
			var content = this.nextElementSibling;
			if (content.style.display === "block") {
				content.style.display = "none";
			} else {
				content.style.display = "block";
			}
		});
	},
	/* updated est appelée à chaque mise à jour de la page */
	updated() {
		this.create_events_collapsible();
	},
	watch: {
		experienceList: {
			deep: true,
			handler() {
				this.updateData();
			}
		}
	}
}
</script>

<style>
.exp_collapsible {
	background-color: #fcfcfc;
	color: #002339;
	cursor: pointer;
	padding: 1rem;
	width: 100%;
	border: #002339 0px;
	text-align: left;
	outline: none;
	font-size: 0.75rem;
	text-transform: uppercase;
	border: 0.0625rem solid #002339;
	margin-top: 0.325rem;
}

.exp_collapsible:after {
	content: '\002B';
	color: #002339;
	float: right;
	margin-left: 0.325rem;
}

.exp_active:after {
	content: "\2212";
}

.exp_collapsible_content {
	border: 0.0625rem solid #002339;
	border-top: 0px;
}

.side_label {
    margin-right: 0.5rem;
    pointer-events: none;
    border-bottom: 0.0625rem solid #00233930;
    text-wrap: nowrap;
    padding: 2px;
}

textarea {
	border: 0.0625rem solid #00233930;
	resize: none;
	width: 100%;
	height: 8rem;
}

.generate_button {
	border: 0.0625rem solid #00233930;
	font-size: 8pt;
	text-transform: uppercase;
	color: #002339;
	margin-left: 1em;
	padding: 0.625rem 1.40625rem;
	background-color: transparent;
	letter-spacing: 0.125rem;
	float: center;
	clear: both;
	cursor: pointer; 
}

.generate_button:hover {
	border: 0.1rem solid #002339;
	transition: all 0.3s ease;
}

.space {
	margin: 2rem 0px;
}

.large_input {
    width: 20rem;
    margin: 0.5rem 0.5rem 0.5rem 2rem;
    padding-bottom: 2px;
}

.side_by_side_container.exp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 2px;
}

.input-wrapper.exp {
    position: relative;
    display: flex;
    align-items: center;
    width: 70%;
    margin: 5px;
}

.select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
}
.select-dropdown {
    position: relative;
    background-color: #E6E6E6;
    border: #f9a825 2px solid;
    border-radius: 4px;
    margin-right: 5px;
}
.select-dropdown select {
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    padding: 2px 24px 2px 3px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.select-dropdown select:active, .select-dropdown select:focus {
	outline: none;
	box-shadow: none;
}
.select-dropdown:after {
	content: "";
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0;
	height: 0;
	margin-top: -2px;
	border-top: 5px solid #f9a825;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    margin: 5px;
    width: 15px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  width: 270px;
  bottom: 120%;
  left: 50%;
  margin-left: -135px; /* Use half of the width (120/2 = 60), to center the tooltip */
  position: absolute;
  z-index: 1;

  
}

@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  animation: scale-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
</style>
