<template>
	<div class="notifications-container">
		<transition-group name="notification-list" tag="div">
			<div v-for="notif in notifications" :key="notif.id" :class="['notification', notif.type]">
				<div class="notification-content">
					{{ notif.message }}
				</div>
				<button @click="closeNotification(notif.id)" class="close-btn">&times;</button>
				<div class="progress-bar">
					<div class="progress" :style="{ width: `${notif.progress}%` }"></div>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
export default {
	data() {
		return {
			notifications: [],
			nextId: 0
		}
	},
	methods: {
		addNotification(message, type = 'success', duration = 5000) {
			const id = this.nextId++
			const startTime = Date.now()
			const endTime = startTime + duration

			const notification = {
				id,
				message,
				type,
				progress: 100,
				duration,
				startTime,
				endTime
			}

			this.notifications.unshift(notification)

			this.updateProgress(id)

			setTimeout(() => {
				this.closeNotification(id)
			}, duration)
		},
		updateProgress(id) {
			const notification = this.notifications.find(n => n.id === id)
			if (notification) {
				const now = Date.now()
				const timeLeft = notification.endTime - now
				if (timeLeft > 0) {
					notification.progress = (timeLeft / notification.duration) * 100
					requestAnimationFrame(() => this.updateProgress(id))
				} else {
					notification.progress = 0
				}
			}
		},
		closeNotification(id) {
			const index = this.notifications.findIndex(n => n.id === id)
			if (index !== -1) {
				this.notifications.splice(index, 1)
			}
		}
	}
}
</script>

<style scoped>
.notifications-container {
	position: fixed;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 300px;
	z-index: 1000;
}

.notification {
	color: white;
	padding: 16px;
	margin-bottom: 10px;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	position: relative;
	overflow: hidden;
}

/* 
	pour rajouter un type de notification rajouter un style ".notification.type"
	exemple :
	.notification.warning {
		background-color: #ff9800;
	}
*/
.notification.success {
	background-color: #4caf50;
}

.notification.info {
		background-color: #ff9800;
}

.notification.error {
	background-color: #f44336;
}

.notification-content {
	margin-right: 20px;
	margin-bottom: 10px;
}

.close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	background: none;
	border: none;
	color: white;
	font-size: 20px;
	cursor: pointer;
}

.progress-bar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 4px;
	background-color: rgba(255, 255, 255, 0.3);
}

.progress {
	height: 100%;
	background-color: white;
	transition: width 0.1s linear;
}

.notification-list-enter-active,
.notification-list-leave-active {
	transition: all 0.5s ease;
}

.notification-list-leave-active {
	width: 89.2%;
	position: absolute;
}

.notification-list-enter-from,
.notification-list-leave-to {
	opacity: 0;
	transform: translateY(-30px);
}
</style>
