import axios from 'axios';
import {BACK_HOST, BACK_PORT} from '@/utils/ip'

function user_exists(user_id) {
	if (user_id == "") {
		return (false);
	}
	axios.post(`https://${BACK_HOST}:${BACK_PORT}/api/user_exists`, 
		{ userId: user_id }, 
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	).then((response) => {
		return (response.data.exists);
	}).catch(() => {
		return (false);
	});
}

export { user_exists };
